<template>
	<div class="container">
		<LoginWrap v-show="showLogin" v-on:close-login="closeLogin()" />
		<div class="mine-top-sec relative">
			<div class="mine-bg-sec">
				<img src="../assets/mine/mine_bg.png" class="mine-bg" />
			</div>
			<div v-if="isLogin" :data-clipboard-text="blockchain"
				class="flex-row align-center space-between blockchain-sec" @click="copy()">
				<div class="copy-text">区块链地址:{{blockchain}}</div>
				<img src="../assets/mine/copy.png" class="copy-icon" />
			</div>

			<div class="flex-row align-center login-news-sec">
				<div class="flex-row align-center login-btn" @click="login()" v-if="!isLogin">
					<div class="login-icon-sec">
						<img src="../assets/mine/to_login_icon.png" class="login-icon" />
					</div>
					<div class="login-text">登录/注册</div>
				</div>
				<div class="flex-row align-center login-btn" @click="loginout()" v-else>
					<img src="../assets/mine/logout_icon.png" class="logout-icon" />
					<div class="login-text">退出登录</div>
				</div>
				<!-- 新消息 -->
				<div class="relative" @click="toNews()" v-if="isLogin">
					<img src="../assets/mine/notice.png" class="news-icon" />
					<div class="news-num">{{remindCount}}</div>
				</div>
			</div>

			<div class="flex-column align-center userinfo-sec" v-if="!isLogin">
				<img src="../assets/mine/head_icon.png" class="head-icon" />
				<div class="username-text">请登录</div>
			</div>
			<div class="flex-column align-center userinfo-sec" v-else>
				<van-uploader :disabled="!isLogin" :max-count="1" :after-read="afterRead">
					<div class="head-sec relative">
						<img v-if="user.pic" :src="user.pic" class="head-icon2" />
						<img v-else src="../assets/mine/head_icon.png" class="head-icon" />
						<img src="../assets/mine/head_edit_icon.png" class="head-edit-icon" />
					</div>
				</van-uploader>
				<div class="username-text">{{name}}</div>
			</div>

			<div class="flex-row align-center fans-sec relative" v-if="isLogin">
				<img src="../assets/mine/fans_icon.png" class="fans-icon" />
				<div class="fans-text">粉丝数</div>
				<div class="fans-xian"></div>
				<div class="fans-num">{{followTotal}}</div>
			</div>
		</div>

		<div class="flex-row align-center menu-sec mt143">
			<div class="flex-column align-center menu-item" @click="navTopic()">
				<img src="../assets/mine/mine_topic_icon.png" class="menu-item-icon" />
				<div class="menu-item-text">我的话题</div>
			</div>
			<div class="flex-column align-center menu-item" @click="navTian()">
				<img src="../assets/mine/mine_tian_icon.png" class="menu-item-icon" />
				<div class="menu-item-text">我的提案</div>
			</div>
			<div class="flex-column align-center menu-item" @click="navMyvote()">
				<img src="../assets/mine/mine_toupiao_icon.png" class="menu-item-icon" />
				<div class="menu-item-text">我的投票</div>
			</div>

			<!-- <div class="flex-column align-center menu-item" @click="navReview()">
				<img src="../assets/mine/mine_review_icon.png" class="menu-item-icon" />
				<div class="menu-item-text">我的评论</div>
			</div>
			<div class="flex-column align-center menu-item" @click="navResponse()">
				<img src="../assets/mine/mine_response_icon.png" class="menu-item-icon" />
				<div class="menu-item-text">我的回复</div>
			</div> -->
		</div>
		<!-- <div class="flex-row align-center menu-sec mt20">
			<div class="flex-column align-center menu-item" @click="navToupiao()">
				<img src="../assets/mine/mine_toupiao_icon.png" class="menu-item-icon" />
				<div class="menu-item-text">我的投票</div>
			</div>
			<div class="flex-column align-center menu-item" @click="navTian()">
				<img src="../assets/mine/mine_tian_icon.png" class="menu-item-icon" />
				<div class="menu-item-text">我的提案</div>
			</div>
		</div> -->
		<img src="../assets/mine/initiate_icon.png" v-if="showBtn" class="initiate-icon" @click="initiate()" />
		<div class="bot-tab-sec flex-row align-center">
			<div class="bot-tab-item flex-column align-center justify-center " @click.stop="navHome()">
				<img src="../assets/tabbar/home_tab.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">首页</div>
			</div>
			<div class="bot-tab-item flex-column align-center justify-center bot-tab-item-active relative">
				<img src="../assets/tabbar/mine_tab_select.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name-active">我的</div>
				<div class="red-dot" v-if="remindCount>0"></div>
			</div>
		</div>
		<div class="icp flex-row align-center space-around">
			<div @click.stop="navBa()">沪ICP备17003041号-4</div>
			<div class="flex-row align-center">
				<!-- <img src="../assets/beiab.png" class="beiab-img"> -->
				<div @click.stop="navBa2()">沪公网安备 31011402009626号</div>
			</div>
		</div>
	</div>
</template>

<script>
	import config from '../config/config.js';
	import util from '../config/util.js';
	import Vue from 'vue';
	import Vant from 'vant';
	import 'vant/lib/index.css';
	import LoginWrap from '@/components/Login.vue';
	import Clipboard from 'clipboard';
	import Compressor from 'compressorjs';
	Vue.use(Vant);

	export default {
		data() {
			return {
				fansSize: 0, //粉丝数
				newsSize: 0, //未读消息数量
				showLogin: false,
				user: {},
				blockchain: '',
				name: '',
				followTotal: '',
				remindCount: '',
				showBtn: 0
			}
		},
		computed: {
			isLogin() {
				return this.$store.state.user.token ? true : false
			}
		},
		components: {
			LoginWrap
		},
		methods: {
			checkUserType() {
				const that = this
				let params = new FormData()
				that.axios.post(config.requestUrl + '/front/forum/checkUserType', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.showBtn=1
					}else{
						that.showBtn=0
					}
				}, response => {
					that.$toast.fail('获取失败，请稍后重试');
				})
			},
			navBa2() {
				window.location.href = "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011402009626"
			},
			navBa() {
				window.location.href = "https://beian.miit.gov.cn/#/Integrated/index"
			},
			afterRead(file) {
				const that = this;
				that.$toast.loading({
					message: '上传中...',
					forbidClick: true,
					duration: 0,
				});

				new Compressor(file.file, {
					success(compressorFile) {
						const formData = new FormData();
						formData.append('file', compressorFile, compressorFile.name);

						that.axios.post(config.requestUrl + '/search/fileUpload?type=1', formData, {
							headers: {
								'Content-Type': 'multipart/form-data',
							}
						}).then(response => {
							console.log()
							if (response.data.code == 0) {
								that.changePic(response.data.data.src)
							} else {
								that.$toast.fail('上传失败')
							}
							console.log(response)
						}, response => {
							that.$toast.fail('上传失败，请稍后重试');
						})
					},
					error(err) {
						that.$toast.fail('上传失败，请稍后重试');
					},
				});

			},
			changePic(src) {
				const that = this
				let params = new FormData()
				params.append('picURL', src)
				that.axios.post(config.requestUrl + '/front/user/changePic', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.user.pic = src;
						that.$toast('上传成功')
					} else {
						that.$toast.fail(response.data.msg);
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								that.login()
								//登录
							}, 1200)
						}
					}
				}, response => {
					that.$toast.fail('获取失败，请稍后重试');
				})
			},
			getRemindCount() {
				const that = this
				let params = new FormData()
				that.axios.post(config.requestUrl + '/front/forum/getRemindCount', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.remindCount = response.data.obj
						console.log(response)
					} else {
						that.$toast.fail(response.data.msg);
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								that.login()
								//登录
							}, 1200)
						}
					}
				}, response => {
					that.$toast.fail('获取失败，请稍后重试');
				})
			},
			getFollowTotal() {
				const that = this
				let params = new FormData()
				that.axios.post(config.requestUrl + '/front/forum/getFollowTotal', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.followTotal = response.data.obj
						console.log(response)
					} else {
						that.$toast.fail(response.data.msg);
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								that.login()
								//登录
							}, 1200)
						}
					}
				}, response => {
					that.$toast.fail('获取失败，请稍后重试');
				})
			},
			copy() {
				var clipboard = new Clipboard('.blockchain-sec')
				clipboard.on('success', e => {
					//  释放内存
					this.$toast.success('复制成功');
				})
				clipboard.on('error', e => {
					// 不支持复制
					// 释放内存
					this.$toast.success('复制失败');
				})
			},
			toNews() {
				this.$router.push('/news')
			},
			// 编辑用户头像
			editHeadImg() {
				this.$refs.upload.click()
				this.$toast('需要编写编辑用户头像逻辑')
			},
			initiate() {
				this.$router.push('/initiate')
			},
			navTopic() {
				if(!this.$store.state.user.token){
					return;
				}
				this.$router.push('/mytopic')
			},
			navReview() {
				this.$toast('敬请期待')
				// this.$router.push('/myreview')
			},
			navResponse() {
				this.$toast('敬请期待')
				// this.$router.push('/myresponse')
			},
			navMyvote() {
				if(!this.$store.state.user.token){
					return;
				}
				this.$router.push('/myvote')
			},
			navTian() {
				if(!this.$store.state.user.token){
					return;
				}
				this.$router.push('/mymotion')
			},
			navHome() {
				this.$router.replace('/')
			},
			login() {
				this.showLogin = true;
			},
			closeLogin() {
				this.showLogin = false;
				if (this.$store.state.user.token) {
					this.getUserInfo();
					this.getFollowTotal();
					this.getRemindCount();
					this.checkUserType();
					// this.getProjectEnd();
				}
			},
			loginout() {
				const that = this;
				that.$dialog.confirm({
						title: '退出登录',
						message: '确认要退出登录？',
					})
					.then(() => {
						console.log('退出登录')
						that.$toast.loading({
							message: '退出中...',
							forbidClick: true,
							duration: 0,
						});
						let params = new FormData()
						that.axios.post(config.requestUrl + '/front/logout', params, {
							headers: {
								'Content-Type': 'multipart/form-data',
								'token': that.$store.state.user.token
							}
						}).then(response => {
							if (response.data.code == 0) {
								localStorage.removeItem("user");
								setTimeout(() => {
									that.$router.push('/')
								}, 1200)
								that.$toast.success('退出成功')
							} else {
								let msg = response.data.msg;
								const str = "未获取到用户信息";
								const str2 = "登录已失效"
								if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
									localStorage.removeItem("user");
									that.$store.commit("clearUser");
									that.$store.commit("clearCode");
									setTimeout(() => {
										that.$router.push('/')
									}, 1200)
								}
								Toast(response.data.msg);
							}
						}, response => {
							that.$toast('获取失败，请稍后重试');
						})
					})
					.catch(() => {
						console.log('取消退出登录')
						// on cancel
					});
			},
			getUserInfo() {
				const that = this
				let params = new FormData()
				that.axios.post(config.requestUrl + '/front/web3/baseInfo', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					that.getBox = 0;
					if (response.data.code == 0) {
						that.user = response.data.obj.userInfo;
						// that.fansSize = response.data.obj.fansSize;//粉丝数
						// that.newsSize = response.data.obj.newsSize;//未读消息数量
						that.blockchain = response.data.obj.userInfo.address; //当前用户的区块链地址
						that.name = util.formatAddress(response.data.obj.userInfo.address)
						that.$toast.clear()
					} else {
						that.$toast.fail(response.data.msg);
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								//登录
							}, 1200)
						}
					}
				}, response => {
					that.$toast.fail('获取失败，请稍后重试');
				})
			}
		},
		mounted() {
			if (this.$store.state.user.token) {
				this.getUserInfo();
			}
		},
		activated() {
			if (this.$store.state.user.token) {
				this.getFollowTotal();
				this.getRemindCount();
				this.checkUserType();
			}
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		height: 100%;
		background: #F5F5F5;
	}

	.mine-top-sec {
		width: 100%;
		height: 315px;
	}

	.mine-bg-sec {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 750px;
		height: 315px;
		overflow: hidden;
	}

	.mine-bg {
		position: absolute;
		top: -16px;
		left: -25px;
		width: 800px;
		height: 331px;
	}

	.login-icon-sec {
		width: 46px;
		height: 46px;
		background: #FFFFFF;
		border-radius: 23px;
		text-align: center;
	}

	.blockchain-sec {
		width: 750px;
		height: 80px;
		background: rgba(0, 0, 0, 0.2);
		position: absolute;
		top: 0;
		left: 0;
		padding: 0 30px;
		box-sizing: border-box;
		word-break: nowrap;
		word-break: break-all;
	}

	.copy-text {
		font-size: 22px;
		color: #000000;
		line-height: 22px;
		letter-spacing: 1px;
	}

	.copy-icon {
		width: 26px;
		height: 26px;
	}

	.login-news-sec {
		position: absolute;
		top: 117px;
		left: 466px;
	}

	.news-icon {
		width: 40px;
		height: 40px;
	}

	.news-num {
		position: absolute;
		top: 0;
		right: -8px;
		height: 16px;
		background: #C90909;
		border-radius: 8px;
		font-size: 14px;
		/* font-weight: bold; */
		color: #FFFFFF;
		line-height: 16px;
		padding: 0 5px;
	}

	.login-btn {
		width: 184px;
		height: 46px;
		background: #000000;
		border-radius: 23px;
		margin-right: 37px;
	}

	.logout-icon {
		width: 46px;
		height: 46px;
	}

	.login-icon {
		width: 26px;
		height: 26px;
		margin-top: 10px;
	}

	.login-text {
		font-size: 26px;
		color: #FFFFFF;
		line-height: 26px;
		margin-left: 13px;
	}

	.userinfo-sec {
		position: absolute;
		top: 205px;
		left: 0;
		width: 346px;
	}

	.head-edit-icon {
		position: absolute;
		top: 65px;
		left: 74px;
		width: 72px;
		height: 72px;
	}

	.head-sec {}

	.head-icon {
		width: 120px;
		height: 120px;

	}

	.head-icon2 {
		width: 120px;
		height: 120px;
		border-radius: 100%;
	}

	.username-text {
		font-size: 30px;
		font-weight: bold;
		color: #000000;
		line-height: 30px;
		margin-top: 38px;
	}

	.fans-sec {
		position: absolute;
		top: 338px;
		right: 30px;
		width: 366px;
		height: 80px;
		background: linear-gradient(199deg, #5850EC 0%, #8C68FF 100%);
		border-radius: 44px;
	}

	.fans-icon {
		width: 104px;
		height: 100px;
		position: absolute;
		top: -6px;
		left: -10px;
	}

	.fans-text {
		font-size: 28px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 28px;
		letter-spacing: 1px;
		margin-left: 97px;
	}

	.fans-xian {
		width: 1px;
		height: 40px;
		background: rgba(255, 255, 255, 0.5);
		border-radius: 1px;
		margin-left: 20px;
	}

	.fans-num {
		width: 160px;
		font-size: 28px;
		font-family: SourceHanSansCN-Bold, SourceHanSansCN;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 42px;
		letter-spacing: 1px;
		text-align: center;
	}

	.menu-sec {
		width: 690px;
		height: 200px;
		background: #FFFFFF;
		border-radius: 40px;
		margin: 0 auto;
	}

	.menu-item {
		width: 33.33%;
	}

	.menu-item-icon {
		width: 47px;
		height: 47px;
	}

	.menu-item-text {
		font-size: 24px;
		color: #000000;
		line-height: 24px;
		margin-top: 21px;
	}

	.initiate-icon {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 150px;
		margin: 0 auto;
		width: 400px;
		height: 80px;
	}

	.mt143 {
		margin-top: 143px;
	}

	.mt20 {
		margin-top: 20px;
	}

	.red-dot {
		width: 14px;
		height: 14px;
		background: #C90909;
		border-radius: 50%;
		position: absolute;
		top: 4px;
		left: 200px;
	}
</style>